<template>
    <v-row>
        <!-- El uso de display none en las columns (en vez de mapear exactamente lo que venga de la api) es exclusivamente debido a la generacion del PDF -->
        <v-col cols="12" sm="6" lg="4" :class="!clasificaciones.isLoading && !microEmpresa ? 'd-none' : ''">
            <CardEstadistica :is-loading="clasificaciones.isLoading">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase">
                        {{ microEmpresa?.clasificacion }}
                    </h4>
                </template>
                <template v-slot:card-text>
                    <div class="d-flex align-center justify-center">
                        <div>
                            <p class="mb-0 text-caption text-uppercase font-weight-bold text-center">Perfil completo</p>
                            <span class="text-h5 font-weight-bold secondary--text d-block text-center">
                                {{ microEmpresa?.numero_proveedores_perfil_completo }}
                            </span>
                        </div>
                    </div>
                </template>
            </CardEstadistica>
        </v-col>
        <v-col cols="12" sm="6" lg="4" :class="!clasificaciones.isLoading && !pequenaEmpresa ? 'd-none' : ''">
            <CardEstadistica :is-loading="clasificaciones.isLoading">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase">
                        {{ pequenaEmpresa?.clasificacion }}
                    </h4>
                </template>
                <template v-slot:card-text>
                    <div class="d-flex align-center justify-center">
                        <div>
                            <p class="mb-0 text-caption text-uppercase font-weight-bold text-center">Perfil completo</p>
                            <span class="text-h5 font-weight-bold secondary--text d-block text-center">
                                {{ pequenaEmpresa?.numero_proveedores_perfil_completo }}
                            </span>
                        </div>
                    </div>
                </template>
            </CardEstadistica>
        </v-col>
        <v-col cols="12" sm="6" lg="4" :class="!clasificaciones.isLoading && !medianaEmpresa ? 'd-none' : ''">
            <CardEstadistica :is-loading="clasificaciones.isLoading">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase">
                        {{ medianaEmpresa?.clasificacion }}
                    </h4>
                </template>
                <template v-slot:card-text>
                    <div class="d-flex align-center justify-center">
                        <div>
                            <p class="mb-0 text-caption text-uppercase font-weight-bold text-center">Perfil completo</p>
                            <span class="text-h5 font-weight-bold secondary--text d-block text-center">
                                {{ medianaEmpresa?.numero_proveedores_perfil_completo }}
                            </span>
                        </div>
                    </div>
                </template>
            </CardEstadistica>
        </v-col>
    </v-row>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import CardEstadistica from '../compartido/CardEstadistica.vue';


export default {
    name: 'NumeroMipymes',
    components: { CardEstadistica },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        clasificaciones: createLoadable([]),
    }),
    computed: {
        microEmpresa() {
            return this.clasificaciones.data.find((clasificacion) => clasificacion.id === 1);
        },
        pequenaEmpresa() {
            return this.clasificaciones.data.find((clasificacion) => clasificacion.id === 2);
        },
        medianaEmpresa() {
            return this.clasificaciones.data.find((clasificacion) => clasificacion.id === 3);
        },
    },
    methods: {
        async cargarNumeroMipymes(filtros) {
            toggleLoadable(this.clasificaciones);
            const { data } = await this.services.ReporteriaMipymes.cargarNumerosMipymes(filtros);
            setLoadableResponse(this.clasificaciones, data);
        },
        manejarEventos(evento, filtros) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarNumeroMipymes(filtros);
        },
    },
    created() {
        // this.cargarNumeroMipymes();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>