<template>
    <v-row align="stretch">
        <v-col cols="12" sm="6" lg="3">
            <CardEstadistica :is-loading="institucionesInformacionReportada.isLoading" style="height: 100%;">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase" style="word-break: break-word;">
                        Instituciones con información reportada 
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs" color="secondary" style="transform: translateY(-2px)">mdi-information</v-icon>
                            </template>
                            <span>Instituciones que tienen al menos un contrato en COMPRASAL</span>
                        </v-tooltip>                        
                    </h4>
                </template>
                <template v-slot:card-text>
                    <span class="text-h6 font-weight-bold secondary--text d-block text-center">
                        {{ institucionesInformacionReportada.data }}
                    </span>
                </template>
            </CardEstadistica>
        </v-col>

        <v-col cols="12" sm="6" lg="3">
            <CardEstadistica :is-loading="comparacionMontosContratados.isLoading" style="height: 100%;">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase">
                        Monto contratado global
                    </h4>
                </template>
                <template v-slot:card-text>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs" class="text-h6 font-weight-bold secondary--text d-block text-center">
                                {{ toMoneyFormat(comparacionMontosContratados.data?.monto_contratado_global, false) }}
                            </span>
                        </template>
                        <span>{{ toMoneyFormat(comparacionMontosContratados.data?.monto_contratado_global) }}</span>
                    </v-tooltip>
                </template>
            </CardEstadistica>
        </v-col>

        <v-col cols="12" sm="6" lg="3">
            <CardEstadistica :is-loading="comparacionMontosContratados.isLoading" style="height: 100%;">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase">
                        Monto contratado por MIP
                    </h4>
                </template>
                <template v-slot:card-text>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs" class="text-h6 font-weight-bold secondary--text d-block text-center">
                                {{ toMoneyFormat(comparacionMontosContratados.data?.monto_contratado_mipymes, false ) }}
                            </span>
                        </template>
                        <span>{{ toMoneyFormat(comparacionMontosContratados.data?.monto_contratado_mipymes) }}</span>
                    </v-tooltip>
                </template>
            </CardEstadistica>
        </v-col>

        <v-col cols="12" sm="6" lg="3">
            <CardEstadistica :is-loading="comparacionMontosContratados.isLoading" style="height: 100%;">
                <template v-slot:card-title>
                    <h4 class="text-subtitle-2 font-weight-bold text-uppercase">
                        % contratado por MIP
                    </h4>
                </template>
                <template v-slot:card-text>
                    <span class="text-h6 font-weight-bold secondary--text d-block text-center">
                        {{ mostrarPorcentaje(comparacionMontosContratados.data?.porcentaje_contratacion_mipymes) }}
                    </span>
                </template>
            </CardEstadistica>
        </v-col>
    </v-row>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import CardEstadistica from '../compartido/CardEstadistica.vue';
import { mostrarPorcentaje, toMoneyFormat } from '@/utils/data';

export default {
    name: 'InstitucionesInformacionReportada',
    components: { CardEstadistica },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        institucionesInformacionReportada: createLoadable(null),
        comparacionMontosContratados: createLoadable(null),
    }),
    methods: {
        toMoneyFormat,
        mostrarPorcentaje,
        async cargarNumeroInstitucionesInformacionReportada(filtros) {
            toggleLoadable(this.institucionesInformacionReportada);
            const { data } = await this.services.ReporteriaMipymes.cargarNumeroInstitucionesInformacionReportada(filtros);
            setLoadableResponse(this.institucionesInformacionReportada, data);
        },
        async cargarComparacionMontosContratados(filtros) {
            toggleLoadable(this.comparacionMontosContratados);
            const { data } = await this.services.ReporteriaMipymes.cargarComparacionMontosContratados(filtros);
            setLoadableResponse(this.comparacionMontosContratados, data);
        },
        manejarEventos(evento, filtros) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarNumeroInstitucionesInformacionReportada(filtros);
            this.cargarComparacionMontosContratados(filtros);
        },
    },
    created() {
        // this.cargarNumeroInstitucionesInformacionReportada();
        // this.cargarNumeroInstitucionesInformacionReportada();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>