<template>
    <div>
        <v-card style="height: 100%; min-height: 400px;" class="chart-card">
            <DarkOverlayLoader v-if="estadisticas.isLoading" />
            <v-card-text class="chart-card-scroller">
                <div class="chart-card-content">
                    <apexchart 
                        type="bar" 
                        height="350" 
                        :options="mapearOptionsGrafica('Monto contratado por entidades', estadisticasEntidades)" 
                        :series="mapearSeriesGrafica(estadisticasEntidades)" 
                        ref="grafico_entidades_ref"
                    />
                </div>
            </v-card-text>
        </v-card>

        <v-card style="height: 100%; min-height: 400px;" class="chart-card mt-4">
            <DarkOverlayLoader v-if="estadisticas.isLoading" />
            <v-card-text class="chart-card-scroller">
                <div class="chart-card-content">
                    <apexchart 
                        type="bar" 
                        height="350" 
                        :options="mapearOptionsGrafica('Monto contratado por municipalidades', estadisticasMunicipalidades)" 
                        :series="mapearSeriesGrafica(estadisticasMunicipalidades)"  
                        ref="grafico_municipalidades_ref"
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import DarkOverlayLoader from '@/components/utils/DarkOverlayLoader.vue';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { toMoneyFormat } from '@/utils/data';
import { pick } from 'lodash';

export default {
    name: 'EstadisticasTipoInstitucion',
    components: { DarkOverlayLoader },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        estadisticas: createLoadable([]),
    }),
    computed: {
        estadisticasEntidades() {
            return this.estadisticas.data.filter((modalidad) => modalidad.id_tipo_entidad === 1);
        },
        estadisticasMunicipalidades() {
            return this.estadisticas.data.filter((modalidad) => modalidad.id_tipo_entidad === 2);
        },
        clasificaciones() {
            const modalidades = this.estadisticasEntidades;
            return modalidades.length > 0 ? modalidades[0].estadisticas.map((clasificacion) => pick(clasificacion, ['id', 'clasificacion'])) : [];
        },
    },
    methods: {
        // UI
        mapearSeriesGrafica(items) {
            const output =  this.clasificaciones.map((clasificacion) => ({
                name: clasificacion.clasificacion,
                data: items.map(modalidad => Number(modalidad.estadisticas.find((estadistica) => estadistica.id === clasificacion.id).monto ?? 0)),
            }));
            
            return output;
        },
        mapearOptionsGrafica(titulo, items) {
            const labels = items.map((modalidad) => modalidad.nombre);

            return ({
                chart: {
                    type: 'bar',
                    height: 400,
                },
                title: {
                    text: titulo,
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    categories: labels,
                },
                fill: {
                    opacity: 1
                },
                yaxis: {
                    labels: {
                        formatter: (valor) => {
                            return toMoneyFormat(valor);
                        },
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        formatter: (value) => {
                            const modalidad = items.find((_modalidad) => _modalidad.nombre === value);

                            return modalidad.nombre;
                        }
                    },
                    shared: true,
                    intersect: false,
                },
            });
        },
        // DATA
         async cargarEstadisticas(filtros) {
            toggleLoadable(this.estadisticas);
            const { data } = await this.services.ReporteriaMipymes.cargarEstadisticasContratacionesPorTipoInstitucion(filtros);
            setLoadableResponse(this.estadisticas, data);
        },
        manejarEventos(evento, filtros) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarEstadisticas(filtros);
        },
        obtenerInstanciaGraficoEntidades() {
            const chart = this.$refs.grafico_entidades_ref.chart;
            return chart;
        },
        obtenerInstanciaGraficoMunicipalidades() {
            const chart = this.$refs.grafico_municipalidades_ref.chart;
            return chart;
        },
    },
    created() {
        // this.cargarEstadisticas();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>