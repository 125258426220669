<template>
    <v-container>
        <v-card>
            <v-card-title class="d-flex align-center" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold" style="word-break: break-word;">
                    Reporte MIP
                  </h2>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <FiltrosSticky icono="mdi-filter-settings">
                    <template v-slot:header-append>
                        <v-btn 
                            @click.stop="generarPDF" 
                            color="secondary" 
                            style="max-width: 175px;"
                            class="mr-2 white--text"
                            :loading="generandoPDF"
                        >
                            <v-icon color="white" class="mr-2">mdi-file-pdf-box</v-icon>
                            Generar PDF
                        </v-btn>
                    </template>
                    <v-row align="center">
                        <v-col cols="12" md="6" lg="4">
                            <v-select
                                v-model="filtros.anio_fiscal"
                                outlined
                                hide-details
                                :items="aniosFiscales.data"
                                :loading="aniosFiscales.isLoading"
                                :disabled="false"
                                item-value="id"
                                item-text="anio"
                                label="Año"
                                placeholder="Seleccione un año"
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-select
                                v-model="filtros.tipo_institucion"
                                outlined
                                hide-details
                                :items="tipoInstituciones.data"
                                :loading="tipoInstituciones.isLoading"
                                :disabled="false"
                                item-value="id"
                                item-text="nombre"
                                label="Tipo de institución"
                                placeholder="Seleccione un tipo de institución"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                                v-model="filtros.clasificacion_empresarial"
                                :items="clasificacionesEmpresariales.data"
                                :loading="clasificacionesEmpresariales.isLoading"
                                label="Clasificación empresarial"
                                placeholder="Seleccione una clasificación"
                                item-value="id"
                                item-text="clasificacion"
                                outlined
                                clearable
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                                v-model="filtros.modalidad"
                                :items="modalidadesFiltradas"
                                :loading="cargandoModalidades"
                                label="Modalidad de compra"
                                placeholder="Seleccione una modalidad"
                                item-value="id"
                                item-text="nombre"
                                outlined
                                clearable
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-checkbox 
                                v-model="filtros.mostrar_estadisticas_mediana_empresa"
                                label="Mostrar información de la mediana empresa" 
                                class="ma-0" 
                                hide-details 
                            />
                        </v-col>
                        <v-col cols="12" lg="4">
                            <div class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap" style="gap: 16px;">
                                <v-btn 
                                    color="primary"
                                    class="flex-grow-1 flex-shrink-1"
                                    x-large
                                    :disabled="generandoPDF"
                                    @click.stop="hacerBusquedaConFiltros"
                                >
                                    Buscar
                                </v-btn>
                                <v-btn 
                                    color="primary" 
                                    class="flex-grow-1 flex-shrink-1"
                                    outlined
                                    x-large
                                    :disabled="generandoPDF"
                                    @click.stop="restablecerInformacion"
                                >
                                    Limpiar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </FiltrosSticky>

                <div id="informacion-instituciones">
                    <h6 class="text-subtitle-1 font-weight-bold text-uppercase secondary--text mt-4">Instituciones</h6>
                    <v-divider class="mt-2 mb-4" />
                    <InstitucionesInformacionReportada 
                        :evento-busqueda="eventoBusquedaFiltros"
                        :listener="institucionesInformacionReportadaListener"
                    />
                </div>

                <EstadisticasTipoInstitucion 
                    class="mt-4" 
                    :evento-busqueda="eventoBusquedaFiltros"
                    :listener="estadisticasTipoInstitucionListener"
                    ref="estadisticasTipoInstitucion"
                />

                <div id="informacion-mipymes">
                    <h6 class="text-subtitle-1 font-weight-bold text-uppercase secondary--text mt-4">MIP Registradas</h6>
                    <v-divider class="mt-2 mb-4" />
                    <v-alert type="info" class="mb-4" dense>
                        El número de MIP registradas y con perfil completo son mostradas a partir del filtro del año fiscal seleccionado.
                    </v-alert>
                    <NumeroMipymes 
                        :evento-busqueda="eventoBusquedaFiltros"
                        :listener="numeroMipymesListener"
                    />
                </div>
                <GraficaAdjudicaciones 
                    class="mt-4" 
                    :evento-busqueda="eventoBusquedaFiltros"
                    :listener="graficaAdjudicacionesListener"
                    ref="graficaAdjudicaciones"
                />
                <GraficaContrataciones 
                    class="mt-4" 
                    :evento-busqueda="eventoBusquedaFiltros"
                    :listener="graficaContratacionesListener"
                    ref="graficaContrataciones"
                />
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import NavButton from '@/components/utils/NavButton.vue';
import NumeroMipymes from './mipymes/NumeroMipymes.vue';
import GraficaAdjudicaciones from './mipymes/GraficaAdjudicaciones.vue';
import GraficaContrataciones from './mipymes/GraficaContrataciones.vue';
import InstitucionesInformacionReportada from './mipymes/InstitucionesInformacionReportada.vue';
import EstadisticasTipoInstitucion from './mipymes/EstadisticasTipoInstitucion.vue';
import FiltrosSticky from './components/FiltrosSticky.vue';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { Listener, Observable } from '@/utils/observable';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const filtrosIniciales = {
    anio_fiscal: null,
    modalidad: null,
    clasificacion_empresarial: null,
    tipo_institucion: null,
    mostrar_estadisticas_mediana_empresa: false,
}

export default {
    components: { 
        NavButton, 
        NumeroMipymes, 
        GraficaAdjudicaciones, 
        GraficaContrataciones, 
        InstitucionesInformacionReportada, 
        EstadisticasTipoInstitucion,
        FiltrosSticky,
    },
    data: () => ({
        // Filtros
        filtros: { ...filtrosIniciales },
        filtrosActivos: { ...filtrosIniciales },
        aniosFiscales: createLoadable([]),
        modalidades: [],
        cargandoModalidades: false,
        clasificacionesEmpresariales: createLoadable([]),
        tipoInstituciones: createLoadable([]),
        generandoPDF: false,
        
        // Listeners
        institucionesInformacionReportadaListener: new Listener('InstitucionesInformacionReportada'),
        estadisticasTipoInstitucionListener: new Listener('EstadisticasTipoInstitucion'),
        numeroMipymesListener: new Listener('NumeroMipymes'),
        graficaAdjudicacionesListener: new Listener('GraficaAdjudicaciones'),
        graficaContratacionesListener: new Listener('GraficaContrataciones'),

        unsubscribers: [],
        eventoBusquedaFiltros: 'BUSCAR_INFORMACION_REPORTE_MIPYMES',
    }),
    computed: {
        modalidadesFiltradas() {
            return this.modalidades.filter((modalidad) => ![18, 27].includes(modalidad.id));
        },
    },
    methods: {
        // UI
        obtenerAnioActual(anios) {
            const anioActual = new Date().getFullYear();
            return anios.find((anio) => anio.anio === anioActual);
        },
        restablecerInformacion() {
            this.filtros = {
                ...this.filtros,
                clasificacion_empresarial: null,
                modalidad: null,
                tipo_institucion: null,
                mostrar_estadisticas_mediana_empresa: false,
            };

            this.filtrosActivos = { ...this.filtros };

            Observable.emit(this.eventoBusquedaFiltros, this.filtros);
        },
        hacerBusquedaConFiltros() {
            this.filtrosActivos = { ...this.filtros };
            Observable.emit(this.eventoBusquedaFiltros, this.filtros);
        },
        // DATA
        async cargarAniosFiscales() {
            toggleLoadable(this.aniosFiscales);
            const { data } = await this.services.PacProcesos.cargarAniosFiscales({ mayor_a: 2023 });
            const anios = Array.isArray(data) ? data : [];
            setLoadableResponse(this.aniosFiscales, { data: anios });

            return anios;
        },
        async cargarModalidades() {
            try {
                this.cargandoModalidades = true;
                const response = await this.services.Paac.getModalidades({ compra_tradicional: true });
                this.modalidades = response.data?.forma_contratacion ?? [];
            } finally {
                this.cargandoModalidades = false;
            }
        },
        async cargarClasificacionesEmpresariales() {
            toggleLoadable(this.clasificacionesEmpresariales);
            const { data } = await this.services.ReporteriaMipymes.cargarClasificacionesEmpresariales({ solo_mipymes: true });
            setLoadableResponse(this.clasificacionesEmpresariales, data);
        },
        async cargarTipoInstituciones() {
            toggleLoadable(this.tipoInstituciones);
            const { data } = await this.services.ReporteriaMipymes.cargarCatalogoTipoInstituciones();
            setLoadableResponse(this.tipoInstituciones, data);
        },
        async agregarContenidoHTML(parametros) {
            const { pdf, identificador, scale = 2, marginX, marginY, width } = parametros;
            const elementoHTML = document.getElementById(identificador);
            const canvas = await html2canvas(elementoHTML, { 
                scale, 
                backgroundColor: '#fff',
                onclone: (document) => {
                    const style = document.createElement('style');
                    style.innerHTML = `
                        .v-card {
                            background: #fff !important;
                            border: solid 1px rgba(0, 0, 0, .12) !important;
                        }

                        .v-icon {
                            display: none !important;
                        }

                        .v-alert {
                            display: none !important;
                        }
                    `;
                    document.head.appendChild(style);
                },
            });

            const imagen = canvas.toDataURL('image/png');


            const pdfWidth = width ?? pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            pdf.addImage(imagen, 'PNG', marginX, marginY, pdfWidth, pdfHeight);

            return pdfHeight;
        },
        crearTablaModalidades(titulo, modalidades) {
            const tabla = document.createElement('table');
            const header = tabla.createTHead();

            const rowTitle = header.insertRow(0);
            const titleColumn = rowTitle.insertCell(0);
            titleColumn.innerHTML = titulo;
            titleColumn.colSpan = 2;
            titleColumn.style.textAlign = 'center';

            const columnHeaders = header.insertRow(1);
            columnHeaders.insertCell(0).textContent = 'Código';
            columnHeaders.insertCell(1).textContent = 'Nombre';


            const body = tabla.createTBody();
            modalidades.forEach((modalidad) => {
                const row = body.insertRow();
                row.insertCell(0).textContent = modalidad.codigo;
                row.insertCell(1).textContent = modalidad.nombre;
            });

            return tabla;
        },
        async generarPDF() {
            try {
                this.generandoPDF = true;
                const pdf = new jsPDF({ orientation: "landscape", unit: "mm", format: "letter" });
                let pdfHeight = 0;

                // Información instituciones
                pdfHeight = await this.agregarContenidoHTML({ pdf, identificador: 'informacion-instituciones', scale: 2, marginX: 20.2, marginY: 6, width: 239 });

                const refGraficoTipoInstitucion = this.$refs.estadisticasTipoInstitucion;

                // Gráfico entidades
                const graficoEntidades = refGraficoTipoInstitucion.obtenerInstanciaGraficoEntidades();
                if (Array.isArray(graficoEntidades.bar.series) && graficoEntidades.bar.series.length > 0) {
                    await this.enableLabelsCharts(graficoEntidades);
                }
                const dataURIGraficoEntidades = await graficoEntidades.dataURI({ scale: 2 });
                pdf.addImage(dataURIGraficoEntidades.imgURI, 'PNG', 20.2, pdfHeight + 12, 239, 100);
                
                await this.disableLabelsCharts(graficoEntidades);
                // Gráfico municipalidades
                const graficoMunicipalidades = refGraficoTipoInstitucion.obtenerInstanciaGraficoMunicipalidades();

                if (graficoMunicipalidades) {
                    if (Array.isArray(graficoMunicipalidades.bar.series) && graficoMunicipalidades.bar.series.length > 0) {
                        await this.enableLabelsCharts(graficoMunicipalidades);
                    }
                    
                    const dataURIGraficoMunicipalidades = await graficoMunicipalidades.dataURI({ scale: 2 });
                    pdf.addPage();
                    pdf.addImage(dataURIGraficoMunicipalidades.imgURI, 'PNG', 20.2, 6, 239, 100);
                    await this.disableLabelsCharts(graficoMunicipalidades);
                }

                // Nueva página
                pdf.addPage();

                // Información MIPYMES
                pdfHeight = await this.agregarContenidoHTML({ pdf, identificador: 'informacion-mipymes', scale: 2, marginX: 20.2, marginY: 6, width: 239 });

                // Gráfico de adjudicaciones
                const refGraficoAdjudicaciones = this.$refs.graficaAdjudicaciones?.obtenerInstanciaGrafico();
                if (refGraficoAdjudicaciones) {
                    await this.enableLabelsCharts(refGraficoAdjudicaciones);
                    const dataURIGraficoAdjudicaciones = await refGraficoAdjudicaciones.dataURI({ scale: 2 });

                    pdf.addImage(dataURIGraficoAdjudicaciones.imgURI, 'PNG', 20.2, pdfHeight + 12, 239, 100);
                    await this.disableLabelsCharts(refGraficoAdjudicaciones);
                    pdfHeight += 92;

                    // Tabla de modalidades
                    const modalidades = this.$refs.graficaAdjudicaciones?.obtenerModalidadesEnGrafico() ?? [];

                    if (modalidades.length > 0) {
                        const tablaHTML = this.crearTablaModalidades('Modalidades en adjudicaciones', modalidades);
                        pdf.addPage();
                        autoTable(pdf, { html: tablaHTML, startY: 12 });
                    }
    
                    // Nueva página
                    pdf.addPage();
                }


                // Gráfico de contrataciones
                const refGraficoContrataciones = this.$refs.graficaContrataciones.obtenerInstanciaGrafico();
                await this.enableLabelsCharts(refGraficoContrataciones);
                const dataURIGraficoContrataciones = await refGraficoContrataciones.dataURI({ scale: 2 });

                pdf.addImage(dataURIGraficoContrataciones.imgURI, 'PNG', 20.2, (refGraficoAdjudicaciones ? 0 : pdfHeight) + 12, 239, 100);
                await this.disableLabelsCharts(refGraficoContrataciones);

                // Tabla de modalidades
                const modalidades = this.$refs.graficaContrataciones?.obtenerModalidadesEnGrafico() ?? [];

                if (modalidades.length > 0) {
                    const tablaHTML = this.crearTablaModalidades('Modalidades en contrataciones', modalidades);
                    pdf.addPage();
                    autoTable(pdf, { html: tablaHTML, startY: 12 });
                }

                pdf.save('reporte.pdf');
            } finally {
                this.generandoPDF = false;
                //document.querySelectorAll('.temporal-tooltip').forEach(element => element.remove()); // Eliminando tooltips temporales
            }
        },
        async enableLabelsCharts(referencia) {
            referencia.updateOptions({
                dataLabels: {
                    enabled: true,
                    textAnchor: 'middle',
                    offsetY: 0,
                    formatter: function (val, opts) {
                        opts.config.dataLabels.offsetY = opts.config.dataLabels.offsetY + 5;
                        if (!val || val === 0) return '';
                        return Number(val).toLocaleString('en-US', { maximumFractionDigits: 2 });
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ["#000"],
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        padding: 4,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: '#000',
                        opacity: 0.9,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                }
            });
        },
        async disableLabelsCharts(referencia) {
            referencia.updateOptions({
                dataLabels: {
                    enabled: false,
                }
            });
        }
    },
    created() {
        this.cargarTipoInstituciones();
        this.cargarModalidades();
        this.cargarClasificacionesEmpresariales();
        this.cargarAniosFiscales().then((anios) => {
            const anio = this.obtenerAnioActual(anios);
            this.filtros.anio_fiscal = anio?.id ?? null;
            this.hacerBusquedaConFiltros();
        });

        this.unsubscribers = [
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.institucionesInformacionReportadaListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.estadisticasTipoInstitucionListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.numeroMipymesListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.graficaAdjudicacionesListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.graficaContratacionesListener),
        ]
    },
    beforeDestroy() {
        this.unsubscribers.forEach((unsubscriber) => unsubscriber());
    },
}
</script>
