<template>
    <div>
        <v-card style="height: 100%; min-height: 400px;" class="chart-card">
            <DarkOverlayLoader v-if="estadisticas.isLoading" />
            <v-card-text class="chart-card-scroller">
                <div class="chart-card-content">
                    <apexchart 
                        ref="grafico_contrataciones" 
                        type="bar" 
                        height="350" 
                        :options="options" 
                        :series="series" 
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import DarkOverlayLoader from '@/components/utils/DarkOverlayLoader.vue';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { toMoneyFormat } from '@/utils/data';
import { pick } from 'lodash';

export default {
    name: 'GraficaContrataciones',
    components: { DarkOverlayLoader },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        estadisticas: createLoadable([]),
    }),
    computed: {
        modalidades() {
            return this.estadisticas.data.map((modalidad) => modalidad.codigo);
        },
        clasificaciones() {
            const modalidades = this.estadisticas.data;
            return modalidades.length > 0 ? modalidades[0].estadisticas.map((clasificacion) => pick(clasificacion, ['id', 'clasificacion'])) : [];
        },
        series() {
            const modalidades = this.estadisticas.data;

            const output =  this.clasificaciones.map((clasificacion) => ({
                name: clasificacion.clasificacion,
                data: modalidades.map(modalidad => Number(modalidad.estadisticas.find((estadistica) => estadistica.id === clasificacion.id).monto ?? 0)),
            }));
            
            return output;
        },
        options() {
            const modalidades = this.estadisticas.data;

            return ({
                chart: {
                    type: 'bar',
                    height: 400,
                },
                title: {
                    text: 'Monto contratado por modalidad de compra',
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    categories: this.modalidades,
                },
                fill: {
                    opacity: 1
                },
                yaxis: {
                    labels: {
                        formatter: (valor) => {
                            return toMoneyFormat(valor);
                        },
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        formatter: (value) => {
                            const modalidad = modalidades.find((_modalidad) => _modalidad.codigo === value);

                            return modalidad.nombre;
                        }
                    },
                    shared: true,
                    intersect: false,
                },
            });
        },
    },
    methods: {
        // DATA
         async cargarEstadisticas(filtros) {
            toggleLoadable(this.estadisticas);
            const { data } = await this.services.ReporteriaMipymes.cargarEstadisticasContratacion(filtros);
            setLoadableResponse(this.estadisticas, data);
        },
        manejarEventos(evento, filtros) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarEstadisticas(filtros);
        },
        obtenerInstanciaGrafico() {
            const chart = this.$refs.grafico_contrataciones.chart;
            return chart;
        },
        obtenerModalidadesEnGrafico() {
            return this.estadisticas.data;
        },
    },
    created() {
        // this.cargarEstadisticas();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>